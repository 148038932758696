import { template as template_84476cb2171b44cb9b0471c17c32b7f8 } from "@ember/template-compiler";
import Footer from '../footer';
import NavbarHeader from '../navbar-header';
export default template_84476cb2171b44cb9b0471c17c32b7f8(`
  <NavbarHeader />
  {{yield}}
  <Footer />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
