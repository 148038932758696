import { template as template_e5e24411418a4227ac03fe4e2a344bb9 } from "@ember/template-compiler";
export default template_e5e24411418a4227ac03fe4e2a344bb9(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
