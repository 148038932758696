import { template as template_6fd24663f22c4e53a4c3974f69454ac5 } from "@ember/template-compiler";
import htmlUnsafe from 'mon-pix/helpers/html-unsafe';
export default template_6fd24663f22c4e53a4c3974f69454ac5(`
  <div class="element-text">
    {{htmlUnsafe @text.content}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
