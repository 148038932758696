import { template as template_0e24fd30904447c1a03484004e5c5999 } from "@ember/template-compiler";
import htmlUnsafe from 'mon-pix/helpers/html-unsafe';
export default template_0e24fd30904447c1a03484004e5c5999(`
  <ol class="module-objectives">
    {{#each @objectives as |objective|}}
      <li class="module-objectives__item">{{htmlUnsafe objective}}</li>
    {{/each}}
  </ol>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
